<div [ngSwitch]="messageToShow">
  <ng-container *ngSwitchCase="MessageType.LOADING">
    <label *ngIf="message">{{ message }}</label>
  </ng-container>
  <ng-container *ngSwitchCase="MessageType.WARNING">
    <label *ngIf="warningMessage">{{ warningMessage }}</label>
  </ng-container>
  <ng-container *ngSwitchCase="MessageType.ERROR">
    <ds-alert *ngIf="errorMessage" [type]="AlertTypeEnum.Error" [content]="errorMessage"></ds-alert>
  </ng-container>
  <ng-container *ngIf="!spinner && messageToShow !== MessageType.ERROR">
    <div class="loader">
      <span class="l-1"></span>
      <span class="l-2"></span>
      <span class="l-3"></span>
      <span class="l-4"></span>
      <span class="l-5"></span>
      <span class="l-6"></span>
      <span class="l-7"></span>
      <span class="l-8"></span>
      <span class="l-9"></span>
      <span class="l-10"></span>
    </div>
  </ng-container>
  <ng-container *ngIf="spinner && messageToShow !== MessageType.ERROR" class="spinner spinner-border" role="status">
    <span class="sr-only">{{ message }}</span>
  </ng-container>
</div>
