<div>
    <h2 *ngIf="searchSection.displayTitle">{{ 'explore.search-section.' + sectionId + '.title' | translate }}</h2>
    <form *ngIf="searchSection.searchType !== 'basic' || !searchSection.searchType" [formGroup]="searchForm" (ngSubmit)="onSubmit(searchForm.value)">
        <div formArrayName="queryArray" *ngFor="let queryStatement of queryArray.controls; let i = index; let isLast = last">
            <div [formGroupName]="i" class="row mb-3 mb-md-0" data-test="form-row">
              <div class="col-md-4 pb-3">
                <select name="filter" id="filter" formControlName="filter" class="form-control mr-2">
                  <option *ngFor="let filter of (filters | async)" [value]="filter">{{'explore.index.' + filter | translate}}</option>
                </select>
              </div>
              <div class="col-md-5 col-xl-6 pb-3">
                <input type="text" name="query" id="query" formControlName="query" class="form-control mr-2"/>
              </div>
              <div class="col-md-3 col-xl-2 pb-3">
                <select *ngIf="!isLast" name="operation" id="operation" formControlName="operation" class="form-control">
                  <option *ngFor="let operation of operations" [value]="operation">{{operation}}</option>
                </select>
                <button *ngIf="isLast" id="addButton" type="button" class="btn btn-info w-100" (click)="addQueryStatement()">+</button>
              </div>
            </div>
        </div>
        <div class="d-flex justify-content-end" style="gap: 20px;">
          <button type="button" id="resetButton" class="btn btn-outline-primary float-right" (click)="onReset()">
            <i class="fas fa-redo mr-1"></i>
            {{'explore.search-section.reset-button' | translate}}
          </button>
          <button type="submit" id="searchButton" class="btn btn-primary float-right">
            <i class="fas fa-search mr-1"></i>
            {{'explore.search-section.search-button' | translate}}
          </button>
        </div>
    </form>

    <div class="col-md-12 d-flex justify-content-center" *ngIf="searchSection.searchType === 'basic'">
        <div class="col-md-8">
            <ds-search-form *ngIf="searchSection.searchType === 'basic'"
                            [inPlaceSearch]="false"
                            [configuration]="searchSection.discoveryConfigurationName"
                            [searchPlaceholder]="'search.search-form.placeholder' | translate"> ></ds-search-form>
        </div>
    </div>
</div>
