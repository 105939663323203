<div *ngIf="!isDismissed" class="alert {{type}} fade show w-100" role="alert" [class.alert-dismissible]="dismissible" [@enterLeave]="animate">
  <button *ngIf="dismissible" type="button" class="close" data-dismiss="alert" attr.aria-label="{{'alert.close.aria' | translate}}" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="d-flex justify-content-between flex-column">
    <div *ngIf="collapsible">
      <ds-truncatable [id]="truncatableId">
        <ds-truncatable-part [minLines]="3" [background]="'transparent'" [id]="truncatableId">
          <span *ngIf="content" [innerHTML]="content | translate"></span>
          <ng-content></ng-content>
        </ds-truncatable-part>
      </ds-truncatable>
    </div>

    <div *ngIf="!collapsible">
      <span *ngIf="content" [innerHTML]="content | translate"></span>
      <ng-content></ng-content>
    </div>

  </div>
</div>
