<div class="container">
  <div class="row">
    <div class="col-12">
      <h3 id="header" class="border-bottom pb-2">{{'reciter.suggestion.title'| translate}}</h3>

      <ds-loading class="container" *ngIf="(isTargetsLoading(source) | async)" message="{{'reciter.suggestion.loading' | translate}}"></ds-loading>
      <ds-pagination *ngIf="!(isTargetsLoading(source) | async)"
                     [paginationOptions]="paginationConfig"
                     [collectionSize]="(totalElements$ | async)"
                     [hideGear]="false"
                     [hideSortOptions]="true"
                     [retainScrollPosition]="false"
                     (paginationChange)="getSuggestionTargets()">

        <ds-loading class="container" *ngIf="(isTargetsProcessing() | async)" message="'reciter.suggestion.loading' | translate"></ds-loading>
        <ng-container *ngIf="!(isTargetsProcessing() | async)">
          <div *ngIf="!(targets$|async) || (targets$|async)?.length == 0" class="alert alert-info w-100 mb-2 mt-2" role="alert">
            {{'reciter.suggestion.noTargets' | translate}}
          </div>
          <div *ngIf="(targets$|async) && (targets$|async)?.length != 0" class="table-responsive mt-2">
            <table id="epeople" class="table table-striped table-hover table-bordered">
              <thead>
                <tr class="text-center">
                  <th scope="col">{{'reciter.suggestion.table.name' | translate}}</th>
                  <th scope="col">{{'reciter.suggestion.table.actions' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let targetElement of (targets$ | async); let i = index" class="text-center">
                  <td>
                    <a target="_blank" [routerLink]="['/items', getTargetUuid(targetElement)]">{{targetElement.display}}</a>
                  </td>
                  <td>
                    <div class="btn-group edit-field">
                      <button (click)="redirectToSuggestions(targetElement.id, targetElement.display)"
                              class="btn btn-outline-primary btn-sm"
                              title="{{'reciter.suggestion.button.review' | translate }}">
                        <span >{{'reciter.suggestion.button.review' | translate: { total: targetElement.total.toString() } }} </span>
                        <i class="fas fa-lightbulb"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </ds-pagination>
    </div>
  </div>
</div>
