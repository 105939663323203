<div class="container">
  <ds-bulk-access-browse [listId]="listId"></ds-bulk-access-browse>
  <div class="clearfix mb-3"></div>
  <ds-bulk-access-settings #dsBulkSettings ></ds-bulk-access-settings>

  <hr>

  <div class="d-flex justify-content-end">
    <a routerLink="/home" class="btn btn-outline-primary mr-3">
            <i class="fas fa-arrow-left mr-1"></i>
      {{ 'access-control-back' | translate }}
    </a>
    <button class="btn btn-primary" [disabled]="!canExport()" (click)="submit()">
      <i class="fas fa-cogs mr-1"></i>
      {{ 'access-control-execute' | translate }}
    </button>
  </div>
</div>



