<a *ngIf="isVisible | async" class="d-flex flex-row"
   [tabIndex]="-1"
   [routerLink]="[searchLink]"
   [queryParams]="addQueryParams" queryParamsHandling="merge">
  <label class="mb-0 d-flex w-100">
    <input type="checkbox" [checked]="false" class="my-1 align-self-stretch"/>
    <span class="w-100 pl-1 break-facet">
      {{ labelTranslation | dsCapitalize}}
      ({{filterValue.count}})
    </span>
  </label>

</a>
