<div *ngIf="isPlatformBrowser && (filters | async)?.hasSucceeded && (filters | async)?.payload?.length > 0" class="container">
  <div class="row">
    <div class="col-md-12 mb-40">
      <div class="d-flex align-items-center mb-2">
        <div class="mr-auto">
          <h2 class="m-0 text-break">{{'search.filters.applied.charts.' + ((configuration | async) ? (configuration | async) : 'default') + '.title' | translate}}</h2>
        </div>
        <button class="btn btn-outline-secondary" data-test="search-charts-toggle"
                ngbTooltip="{{'search.sidebar.show.hide.charts.tooltip' | translate}}"
                (click)="toggleChart()" *ngIf="showChartsToggle">
          <i class="fa fa-chart-line mr-2"></i> {{"search.filters.applied.charts.show.hide" | translate}}</button>
      </div>
      <div *ngIf="!collapseChart && selectedFilter" @shrinkInOut data-test="search-charts">
        <ul class="nav nav-pills mb-2">
          <ng-container *ngFor="let filter of (filters | async)?.payload; trackBy: trackUpdate; let i = index">
            <li class="nav-item mr-3" *ngIf="(canShowChart(filter) | async)"
                (click)="changeChartType(filter)">
              <a class="nav-link" [ngClass]="{'active' : selectedFilter.name == filter.name}" data-test="search-charts-tab">
                {{'search.filters.applied.charts.' + filter.name + '.tab' | translate}}
              </a>
            </li>
          </ng-container>
        </ul>
        <div *ngIf="(canShowChart(selectedFilter) | async)">
          <ds-search-chart [filter]="selectedFilter"
                           [inPlaceSearch]="inPlaceSearch"
                           [refreshFilters]="refreshFilters"></ds-search-chart>
        </div>
      </div>
    </div>
  </div>
</div>
