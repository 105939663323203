<div>
  <div class="table-responsive" *ngIf="evidences">
    <table class="table">
      <thead>
        <tr>
          <th>{{'reciter.suggestion.evidence.score' | translate}}</th>
          <th>{{'reciter.suggestion.evidence.type' | translate}}</th>
          <th>{{'reciter.suggestion.evidence.notes' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let evidence of evidences | dsObjectKeys">
          <td>{{evidences[evidence].score}}</td>
          <td>{{evidence | translate}}</td>
          <td>{{evidences[evidence].notes}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
