<div class="d-flex flex-nowrap flex-row gapx-3 align-items-center pr-3" *ngIf="remark && canLoadScript && !(isHidden$ | async)">
  <div [ngSwitch]="(remark | dsListMetricProps: 'type':isListElement)" [class.person-type-plumX]="isListElement">
    <ng-container *ngSwitchCase="'Person'">
      <a *ngIf="remark && (remark | dsListMetricProps: 'data-person-badge-enabled':isListElement == true)"
         [href]="remark | dsListMetricProps: 'href':isListElement"
         class="plumx-person"
         data-site="plum"
         [attr.data-no-name]="remark | dsListMetricProps: 'data-no-name':isListElement"
         [attr.data-num-artifacts]="remark | dsListMetricProps: 'data-num-artifacts':isListElement"
         [attr.data-width]="remark | dsListMetricProps: 'data-width':isListElement"
         [attr.data-no-description]="remark | dsListMetricProps: 'data-no-description':isListElement"
         [attr.data-no-stats]="remark | dsListMetricProps: 'data-no-stats':isListElement"
         [attr.data-no-thumbnail]="remark | dsListMetricProps: 'data-no-thumbnail':isListElement"
         [attr.data-no-artifacts]="remark | dsListMetricProps: 'data-no-artifacts':isListElement"
      >{{ remark | dsListMetricProps: "placeholder":isListElement }}</a>
    </ng-container>
    <ng-container *ngSwitchCase="'Publication'">
      <a *ngIf="remark && (remark | dsListMetricProps: 'data-publication-badge-enabled':isListElement == true)"
         [href]="remark | dsListMetricProps: 'href':isListElement"
         class="plumx-plum-print-popup"
         [attr.data-popup]="remark | dsListMetricProps: 'data-popup':isListElement"
         [attr.data-hide-when-empty]="visibleWithoutData ? false : (remark | dsListMetricProps: 'data-hide-when-empty':isListElement)"
         [attr.data-hide-usage]="remark | dsListMetricProps: 'data-hide-usage':isListElement"
         [attr.data-hide-captures]="remark | dsListMetricProps: 'data-hide-captures':isListElement"
         [attr.data-hide-mentions]="remark | dsListMetricProps: 'data-hide-mentions':isListElement"
         [attr.data-hide-socialmedia]="remark | dsListMetricProps: 'data-hide-socialmedia':isListElement"
         [attr.data-hide-citations]="remark | dsListMetricProps: 'data-hide-citations':isListElement"
         [attr.data-pass-hidden-categories]="remark | dsListMetricProps: 'data-pass-hidden-categories':isListElement"
         [attr.data-detail-same-page]="remark | dsListMetricProps: 'data-detail-same-page':isListElement"
      >{{ remark | dsListMetricProps: "placeholder":isListElement }}</a>
    </ng-container>
  </div>
  <div class="font-weight-bold" *ngIf="!hideLabel">
    {{ "item.page.metric.label." + metric.metricType | translate }}
  </div>
</div>
<div class="row d-flex align-items-center justify-content-center m-2 alert-light"  *ngIf="!canLoadScript && !isListElement">
  <div>
    {{ "third-party-metrics-cookies.message" | translate: {metricType: metric.metricType | titlecase} }}
    <div role="button" class="btn-link" (click)="requestSettingsConsent.emit(true)">{{"third-party-metrics-cookies.consent-settings" | translate}}</div>
  </div>
</div>
