<div class="row">
  <div *ngIf="showThumbnails" class="col-3 col-xl-2 pr-sm-0">
    <ds-thumbnail [thumbnail]="item?.thumbnail | async" [limitWidth]="true">
    </ds-thumbnail>
  </div>
  <div [ngClass]="showThumbnails ? 'col-9 col-xl-10' : 'col-md-12'">
    <div *ngIf="item" @fadeInOut>
      <div class="d-flex">
        <ds-themed-badges [object]="item" [context]="badgeContext" [showAccessStatus]="true"></ds-themed-badges>
      </div>
      <ds-truncatable [id]="item.id">
        <h3 [innerHTML]="dsoTitle" [ngClass]="{'lead': true,'text-muted': !item.firstMetadataValue('dc.title')}"></h3>
        <div>
          <span class="text-muted">
            <ds-truncatable-part [id]="item.id" [minLines]="1">
              (<span *ngIf="item.hasMetadata('dc.publisher')" class="item-list-publisher"
                     [innerHTML]="item.firstMetadataValue('dc.publisher') + ', '"></span>
              <span class="item-list-date" [innerHTML]="item.firstMetadataValue('dc.date.issued') || ('mydspace.results.no-date' | translate)"></span>)
              <span *ngIf="item.hasMetadata(authorMetadata);" class="item-list-authors">
                <span *ngIf="item.allMetadataValues(authorMetadata).length === 0">{{'mydspace.results.no-authors' | translate}}</span>
                <span *ngFor="let author of item.allMetadata(authorMetadata); let i=index; let last=last;">
                  <ds-metadata-link-view *ngIf="!!item && !!author" [item]="item" [metadataName]="authorMetadata"
                                         [metadata]="author"></ds-metadata-link-view><span
                  *ngIf="!last">; </span>
                </span>
              </span>
            </ds-truncatable-part>
          </span>

          <ds-truncatable-part [id]="item.id" [minLines]="1" class="item-list-abstract">
            <span [ngClass]="{'text-muted': !item.firstMetadataValue('dc.description.abstract')}"
                  [innerHTML]="(item.firstMetadataValue('dc.description.abstract')) || ('mydspace.results.no-abstract' | translate)"></span>
          </ds-truncatable-part>

          <ds-additional-metadata [object]="item"></ds-additional-metadata>

        </div>
      </ds-truncatable>
      <ds-item-correction [item]="item"></ds-item-correction>
      <ds-item-submitter *ngIf="showSubmitter" [object]="object.indexableObject"></ds-item-submitter>
      <ds-item-collection *ngIf="object.indexableObject" [object]="object.indexableObject"></ds-item-collection>
    </div>
  </div>
</div>
