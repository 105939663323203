<div class="jumbotron jumbotron-fluid mt-ncs">
  <div class="container">
    <div class="background-image">
      <div class="container">
        <div class="jumbotron jumbotron-fluid">
          <ds-themed-text-section *ngIf="hasHomeNewsMetadata; else dsDefaultHomeNews" [sectionId]="'site'" [textRowSection]="homeNewsSection"
            [site]="(site$ | async)"></ds-themed-text-section>
        </div>
      </div>
      <small class="credits">Photo by
        <a href="https://www.pexels.com/@inspiredimages">@inspiredimages</a>
      </small>
    </div>
  </div>
</div>

<ng-template #dsDefaultHomeNews>
  <div class="d-flex flex-wrap">
    <div>
      <h1 class="display-3">DSpace Cris 7</h1>
      <p class="lead">DSpace is the world leading open source repository platform that enables organisations to:</p>
    </div>
  </div>
  <ul>
    <li>easily ingest documents, audio, video, datasets and their corresponding Dublin Core metadata
    </li>
    <li>open up this content to local and global audiences, thanks to the OAI-PMH interface and Google Scholar optimizations
    </li>
    <li>issue permanent urls and trustworthy identifiers, including optional integrations with handle.net and DataCite DOI</li>
  </ul>
  <p>Join an international community of
    <a href="https://wiki.lyrasis.org/display/DSPACE/DSpace+Positioning" target="_blank">leading institutions using DSpace</a>.
  </p>
</ng-template>