<ng-container [ngSwitch]="textRowSection?.contentType">
  <div *ngSwitchCase="'image'"><img [src]="textRowSection.content" style="width: 100%"></div>
  <div *ngSwitchCase="'text-key'">{{ ('explore.text-section.' + textRowSection.content) | translate }}</div>
  <div *ngSwitchCase="'text-raw'">{{ textRowSection.content }}</div>
  <div *ngSwitchCase="'text-metadata'" [class.text-section-home-news]="textRowSection.content === 'cris.cms.home-news'">
    <ds-markdown-viewer data-test="ds-markdown-viewer"
                        [value]="metadataValue(textRowSection.content)"></ds-markdown-viewer>
  </div>
  <div *ngSwitchCase="'custom'">
    <ng-container *ngTemplateOutlet="custom"></ng-container>
  </div>
</ng-container>

<!-- Custom content type -->
<ng-template #custom></ng-template>
