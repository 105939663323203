<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ (labelPrefix + label + '.title') | translate }}</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <small>{{ (labelPrefix + label + '.publication' | translate) }}</small>
  <div class="mb-3">
    <div class="text-truncate">
      <a target="_blank" [routerLink]="['/items', (externalSourceEntry.event.target|async)?.payload?.id]">
        {{externalSourceEntry.title}}
      </a>
    </div>
  </div>
  <div *ngIf="externalSourceEntry.projectTitle">
    <small>{{ (labelPrefix + label + '.bountToLocal' |translate) }}</small>
    <div class="mb-3">
      <div class="text-truncate">
        <a target="_blank" [routerLink]="['/items', externalSourceEntry.projectId]">
          {{externalSourceEntry.projectTitle}}
        </a>
      </div>
    </div>
  </div>

  <h4>{{ (labelPrefix + label + '.select' | translate) }}</h4>

  <div id="project-entities" class="mb-3">

    <div id="project-search" class="input-group mb-3">
      <input type="text" class="form-control" (keyup.enter)="search(projectTitle)" [(ngModel)]="projectTitle" placeholder="{{labelPrefix + label + '.placeholder' |translate}}" aria-label="" aria-describedby="">
      <div class="input-group-append">
        <button type="button" class="btn btn-outline-secondary" [disabled]="projectTitle === ''" (click)="projectTitle = ''">{{(labelPrefix + label + '.clear'|translate)}}</button>
        <button type="button" class="btn btn-primary" [disabled]="projectTitle === ''" (click)="search(projectTitle)">{{(labelPrefix + label + '.search'|translate)}}</button>
      </div>
    </div>

    <ds-loading *ngIf="(isLoading$ | async)" message="{{'loading.search-results' | translate}}"></ds-loading>
    <ds-search-results *ngIf="(localEntitiesRD$ | async)?.payload?.page?.length > 0 && !(isLoading$ | async)"
                       [searchResults]="(localEntitiesRD$ | async)"
                       [sortConfig]="this.searchOptions?.sort"
                       [searchConfig]="this.searchOptions"
                       [selectable]="true"
                       [disableHeader]="true"
                       [hidePaginationDetail]="false"
                       [selectionConfig]="{ repeatable: false, listId: entityListId }"
                       [linkType]="linkTypes.ExternalLink"
                       [context]="context"
                       (deselectObject)="deselectEntity()"
                       (selectObject)="selectEntity($event)">
    </ds-search-results>

    <div *ngIf="(localEntitiesRD$ | async)?.payload?.page?.length < 1 && !(isLoading$ | async)">
      <ds-alert [type]="'alert-info'">
        <p class="lead mb-0">{{(labelPrefix + label + '.notFound' | translate)}}</p>
      </ds-alert>
    </div>

  </div>
</div>
<div class="modal-footer">
  <div>
    <button type="button" class="btn btn-outline-secondary" (click)="close()">{{ (labelPrefix + label + '.cancel' | translate) }}</button>
  </div>
  <div>
    <button type="button" class="btn btn-primary" [disabled]="selectedImportType === importType.None" (click)="bound()">{{ (labelPrefix + label + '.bound' | translate) }}</button>
  </div>
</div>
