<div class="d-flex flex-nowrap flex-row gapx-3 align-items-center pr-3"
     *ngIf="(!failed &&
      canLoadScript &&
      !(isHidden$ | async) &&
      (remark | dsListMetricProps: 'data-badge-enabled':isListElement == true))"
>
  <div #metricChild>
    <div
      class="altmetric-embed"
      [attr.data-hide-no-mentions]="visibleWithoutData ? false : (remark | dsListMetricProps : 'data-hide-no-mentions' : isListElement)"
      [attr.data-hide-less-than]="remark | dsListMetricProps : 'data-hide-less-than' : isListElement"
      [attr.data-badge-details]="remark | dsListMetricProps : 'data-badge-details' : isListElement"
      [attr.data-badge-type]="remark | dsListMetricProps : 'badgeType' : isListElement"
      [attr.data-badge-popover]="remark | dsListMetricProps : 'popover' : isListElement"
      [attr.data-doi]="remark | dsListMetricProps : 'doiAttr' : isListElement"
      [attr.data-pmid]="remark | dsListMetricProps : 'pmidAttr' : isListElement"
      [attr.data-link-target]="remark | dsListMetricProps : 'data-link-target' : isListElement"
    ></div>
  </div>
  <div class="font-weight-bold" *ngIf="!hideLabel">
    {{ "item.page.metric.label." + metric.metricType | translate }}
  </div>
</div>
<div class="row d-flex align-items-center justify-content-center m-2"  *ngIf="!canLoadScript && !isListElement">
  <div>
    {{ "third-party-metrics-cookies.message" | translate: {metricType: metric.metricType | titlecase} }}
    <div role="button" class="btn-link" (click)="requestSettingsConsent.emit(true)">{{"third-party-metrics-cookies.consent-settings" | translate}}</div>
  </div>
</div>
